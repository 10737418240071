
import { defineComponent, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Private from "@/views/reusable/buy-insurance/motor-insurance/Private.vue";
import Commercial from "@/views/reusable/buy-insurance/motor-insurance/Commercial.vue";
import Truck from "@/views/reusable/buy-insurance/motor-insurance/Truck.vue";
import { Field, ErrorMessage } from "vee-validate";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import store from "@/store";

export default defineComponent({
  name: "motor-insurance-quote",
  components: {
    Field,
    ErrorMessage,
    Private,
    Commercial,
    Truck,
    TotalPremiumWidget,
  },
  data() {
    return {
      vehicleType: "",
      displayPMI: false,
      displayCMI: false,
      displayTMI: false,
      privateMotorInsurance: false,
      commercialMotorInsurance: false,
      truckMotorInsurance: false,
      covers: [],
      result: {
        privateCartItemPublicId: "",
        commercialCartItemPublicId: "",
        hasPrivateCartItem: true || (false as boolean),
        hasCommercialCartItem: true || (false as boolean),
      },
    };
  },
  setup() {
    const user = computed(() => {
      return store.getters.currentUser;
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Quote", [
        "Insurance Packages",
        "Motor Insurance",
      ]);
    });

    return { user };
  },
  created() {
    this.hasExistingCartPackages();

    //Set page title
    document.title = "Motor Insurance Quote | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    hasExistingCartPackages() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.CHECK_CART_PACKAGE_ROUTE}/MOT/new`)
          .then(({ data }) => {
            //Assign data to form fields
            this.result = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },

    privateMotorInsuranceType() {
      this.commercialMotorInsurance = false;
      this.truckMotorInsurance = false;
      this.displayCMI = false;
      variables.resetPremium();

      if (this.result.hasPrivateCartItem) {
        this.privateMotorInsurance = false;
        this.displayPMI = true;
      } else {
        this.privateMotorInsurance = true;
        this.displayPMI = false;
      }

      // if (JwtService.getToken()) {
      //   ApiService.setHeader();
      //   ApiService.get(variables.PRIVATE_MOTOR_RATE_ROUTE)
      //     .then(({ data }) => {
      //       //Assign data to form fields
      //       this.covers = data.data;
      //     })
      //     .catch(function (error) {
      //       variables.toastAlert(error.response.data.error, "error");
      //     });
      // }
    },

    commercialMotorInsuranceType() {
      this.privateMotorInsurance = false;
      this.truckMotorInsurance = false;
      this.displayPMI = false;
      variables.resetPremium();

      if (this.result.hasCommercialCartItem) {
        this.commercialMotorInsurance = false;
        this.displayCMI = true;
      } else {
        this.commercialMotorInsurance = true;
        this.displayCMI = false;
      }

      // if (JwtService.getToken()) {
      //   ApiService.setHeader();
      //   ApiService.get(variables.COMMERCIAL_MOTOR_RATE_ROUTE)
      //     .then(({ data }) => {
      //       //Assign data to form fields
      //       this.covers = data.data;
      //     })
      //     .catch(function (error) {
      //       variables.toastAlert(error.response.data.error, "error");
      //     });
      // }
    },
    truckMotorInsuracnceType() {
      this.truckMotorInsurance = true;
      this.privateMotorInsurance = false;
      this.commercialMotorInsurance = false;
      this.displayPMI = false;
      this.displayCMI = false;
      variables.resetPremium();
    },
  },
});
