
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import MotorInsuranceForm from "@/components/reusable/MotorInsuranceForm.vue";
import { Form } from "vee-validate";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import CooperativeMotorInsuranceForm from "@/components/reusable/CooperativeMotorInsuranceForm.vue";
import VehicleBodyTypes from "@/views/reusable/buy-insurance/motor-insurance/vehicle-body-types.json";

export default defineComponent({
  name: "commercial-motor-insurance-selection",
  components: {
    Form,
    MotorInsuranceForm,
    CooperativeMotorInsuranceForm,
  },
  data() {
    return {
      userRole: "",
      bodyTypes: VehicleBodyTypes.commercial,
    };
  },
  props: {
    covers: Array,
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();
    const store = useStore();
    const userType = store.getters.currentUser;
    const underwriters = ref([
      {
        id: "",
        logo: "",
        name: "",
        policyCount: 0,
        publicId: "",
        yearOfEstablishment: 1900,
      },
    ]);

    const metadataValidator = Yup.object().shape({
      metadata: Yup.array()
        .of(
          Yup.object().shape({
            vehicleOwner: Yup.string()
              .required()
              // .min(6)
              .label("Vehicle owner name"),
            vehicleValue: Yup.string()
              .when("package", {
                is: (value) => value != JwtService.getThirdPartyPM(),
                then: Yup.string()
                  // .min(6)
                  .required(),
              })
              .label("Vehicle value"),
            package: Yup.mixed()
              .required()
              .label("Cover"),
            vehicleMake: Yup.mixed()
              .required()
              .label("Vehicle brand"),
            vehicleModel: Yup.string()
              .required()
              .label("Vehicle model"),
            vehicleRegistrationNumber: Yup.string()
              .required()
              // .min(8)
              .label("Vehicle registration number"),
            chassisNumber: Yup.string()
              .required()
              .min(1)
              .label("Chassis number"),
            engineNumber: Yup.string()
              .notRequired()
              .min(1)
              .label("Engine number"),
            bodyType: Yup.string()
              .required()
              .label("Body type"),
            vehicleColor: Yup.string()
              .notRequired()
              .label("Vehicle colour"),
            vehicleLicense: Yup.mixed()
              .notRequired()
              .label("Vehicle license"),
          })
        )
        .strict(),
    });

    const saveCommercial = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            const formData = new FormData();

            for (let x = 0; x < values["metadata"].length; x++) {
              for (const [key, value] of Object.entries(
                values["metadata"][x]
              )) {
                if (Array.isArray(value)) {
                  value.forEach((itemValue, index) => {
                    formData.append(
                      `vehicleDetails[${key}][${x}][${index}]`,
                      itemValue as string | Blob
                    );
                  });
                } else {
                  formData.append(
                    `vehicleDetails[${key}][${x}]`,
                    value as string | Blob
                  );
                }
              }
            }

            formData.append("totalPremium", values["totalPremium"]);
            formData.append("totalSumInsured", values["totalSumInsured"]);
            formData.append("businessType", values["businessType"]);
            formData.append("businessClass", values["businessClass"]);
            formData.append("underwriter", values["underwriter"]);
            // return new Response(formData).text().then(console.log);

            if (formData.get("underwriter") == "") {
              variables.toastAlert(
                "Kindly select an underwriter before continuing with this action.",
                "success"
              );
            }

            ApiService.setHeader();
            ApiService.post(variables.CART_ROUTE, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                //Update cart information
                store.commit(Mutations.CART_MUTATION, response.data.data.cart);

                // Reset form
                resetForm();
                variables.toastAlert(response.data.data.message, "success");
                router.push({
                  name: "insurance-packages-policy-period",
                  params: {
                    publicId: response.data.data.cartItemPublicId,
                  },
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    // Function to get underwriters
    const getUnderwriters = async () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.MOTOR_UNDERWRITERS_ROUTE}/CM`)
          .then(({ data }) => {
            //Assign data to form fields
            underwriters.value = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    onMounted(() => {
      getUnderwriters();

      setCurrentPageBreadcrumbs("Commercial", [
        "Insurance Packages",
        "Motor Insurance",
        "Quote",
      ]);
    });

    return {
      submitButton1,
      saveCommercial,
      metadataValidator,
      userType,
      underwriters,
    };
  },
  created() {
    //Set page title
    document.title =
      "Commercial Motor Insurance | " + process.env.VUE_APP_TITLE;
    this.userRole = variables.COOPERATIVE_ADMIN_USER_ROLE;
  },
});
